body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.x-table-column {
  display: table-cell;
}

.x-table-column-summary {
  display: none;
}

.p-column-title {
  white-space: nowrap;
}

.p-datatable-header {
  background-color: transparent !important;
  padding-left: 0px !important;
  border-top: none !important;
}

.x-header {
  display: flex;
  justify-content: space-between;
}

.x-header-buttons {
  display: flex;
  flex-wrap: nowrap;
}

.x-header-buttons-search {
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 768px) {

  .x-table-column {
    display: none;
  }

  .x-table-column-summary {
    display: table-cell;
    min-width: none;
  }

  .x-button {
    width: 25px !important;
    height: 20px !important;
  }

  .x-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .x-header-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .x-header-buttons-search {
    display: flex;
    flex-wrap: nowrap;
  }

}

.p-scrollpanel-bar {
  background-color: var(--surface-300);
  border-radius: 0;
  opacity: 1;
  transition: background-color .3s;
}

.p-tabview .p-tabview-nav {
  border: none !important;
}

.PhoneInput input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px !important;
}
